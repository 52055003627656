window.addEventListener('DOMContentLoaded', (event) => {
    const menuBtn = document.querySelector('.menu')
    const nav = document.querySelector('.nav')
    const curtain = document.querySelector('.curtain')
    const toggleNav = function() {
        menuBtn.classList.toggle('active')
        curtain.classList.toggle('curtain--pulled')
        nav.classList.toggle('nav--open')
    }

    menuBtn.addEventListener('click', toggleNav)
});
