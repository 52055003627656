// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()



// Styles
import 'scss/vendors.scss'
import 'scss/style.scss'

// Js
import 'js/site.js'

// Images
import 'images/brand/main-logo-minimal.png'
import 'images/brand/common-logo-white-minimal.png'
import 'images/brand/academies/horizontal/csay.png'
import 'images/brand/academies/horizontal/gyulay.png'
import 'images/brand/academies/horizontal/kammerer.png'
import 'images/brand/academies/horizontal/kokeny.png'
import 'images/brand/academies/horizontal/vajda.png'
import 'images/brand/academies/horizontal/wichmann.png'
